
function App() {
  return (
    <div className="grid md:grid-cols-2 grid-cols-1 gap-3 h-full px-10">
      <div className="flex justify-center items-center flex-col flex-1">
        <div style={{ maxWidth: 500 }}>
          <h1 className="text-2xl font-bold mb-3">Inter is an AI company.</h1>
          <p className="text-lg font-medium mb-5">We're building AI-powered platforms & apps that empower people and businesses, and redefine
          how they learn, work, and play.</p>

          <div className="flex flex-row items-center mb-5">
            <a href="mailto:inter@studyable.app" className="text-md text-gray-400 font-semibold mr-4">Contact</a>

         
          </div>

          <p className="text-xs text-gray-500 font-medium mr-4">&copy; {new Date().getFullYear()} Inter Platforms Pty Ltd.</p>
        </div>
      </div>

      <div className="flex flex-row justify-center md:items-center items-start">

        <a href="https://studyable.app" target="_blank">
          <img src="/products/studyable.png" className="h-20 w-20" />

        </a>

        <a href="https://usenotate.com" target="_blank" className="md:ml-20 ml-10">
          <img src="/products/notate.png" className="h-20 w-20" />

        </a>

      </div>
    </div>
  );
}

export default App;
